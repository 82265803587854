.logo-container {
    z-index: 4;
    width: 400px;
    height: 530px;
    position: absolute;
    top: 70;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;


    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        z-index: 1;
    }
}

.svg-container {
    stroke: #fff;
    stroke-width: 10px;
}