.home-page {

.text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
}

    h1 {
        color: #80CFA9;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #80CFA9;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

&::after {
    content: '</h1>';
    font-family: 'La Belle Aurore';
    color: #80CFA9;
    font-size: 18px;
    position: absolute;
    margin-top: 18px;
    margin-left: 20px;
    animation: fadeIn 1s 1.7s backwards;
    opacity: 0.6;
}

        img{
            width: 32px;
            margin-left: 20px;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }
}

h2 {
    color: #fff;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
    opacity: 0.8;
}

.flat-button {
    color: #80CFA9;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #80CFA9;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

        &:hover {
        background: #80CFA9;
        color: #225560;
    }
}

@media screen and (max-width: 1200px) {
    .tags, .home-page h1::before, .home-page h1::after {
        display: none;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }
    }
}